<template>
  <div>
    <v-alert type="info"> <b>Note: Only import .csv file</b> </v-alert>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" class="border">
          <v-row>
            <v-col cols="12" v-if="informText != ''">
              <v-alert type="error">
                {{ this.informText }}
              </v-alert>
            </v-col>

            <v-col cols="7" style="text-align: left">
              <v-icon>mdi-file-upload-outline</v-icon>
              <template v-if="selectedFileData.fileName == ''">
                &nbsp; No File Selected</template
              >
              <template
                v-else
                v-for="(value, text, index) in this.selectedFileData"
              >
                {{ text }} : {{ value }}<br :key="index" />
              </template>
            </v-col>
            <v-col cols="5" style="text-align: right">
              <input
                type="file"
                @change="onFileSelected"
                ref="inputPreview"
                style="display: none"
              />
              <v-btn
                small
                :loading="loading"
                :disabled="loading"
                color="blue-grey"
                class="white--text"
                @click="$refs.inputPreview.click()"
                v-if="!loadBtn"
              >
                select order file
                <v-icon right dark>mdi-calendar-import</v-icon>

                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-loading</v-icon>
                  </span>
                </template>
              </v-btn>

              <v-btn
                small
                color="blue-grey"
                class="white--text"
                v-else
                disabled
              >
                select order file
                <v-icon right dark>mdi-calendar-import</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-btn
            small
            class="white--text"
            :loading="loading2"
            :disabled="loading2"
            color="deep-purple darken-1"
            @click="
              loader = 'loading2';
              uploadData();
            "
            v-if="arrayData.length > 0"
          >
            Upload Data
            <v-icon right dark>mdi-cloud-upload</v-icon>
            <template v-slot:loader>
              <span style="color: black">{{ percent }} %</span>
            </template>
          </v-btn>

          <v-btn
            small
            class="white--text"
            color="deep-purple darken-1"
            v-else
            disabled
            >Upload Data <v-icon right dark>mdi-cloud-upload</v-icon></v-btn
          >

          &nbsp;

          <v-btn
            small
            class="white--text"
            color="warning"
            @click="cancelData"
            v-if="loader == null"
          >
            Cancel <v-icon right dark>mdi-window-close</v-icon>
          </v-btn>
          <v-btn small class="white--text" color="warning" v-else disabled>
            Cancel <v-icon right dark>mdi-window-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" v-if="arrayData.length > 0">
      Preview Data: {{ arrayData.length }} row(s)
      <v-data-table
        :items="arrayData"
        :headers="headers"
        :hide-default-footer="true"
        class="elevation-1"
        height="60vh"
        disable-pagination
        fixed-header
      ></v-data-table>
    </v-col>
  </div>
</template>

<script>
import axios from "axios";
import Amplify from "aws-amplify";
import VuePapaParse from "vue-papa-parse";
import Vue from "vue";
Vue.use(VuePapaParse);

Amplify.configure({
  Auth: {
    region: "ap-southeast-1",
    userPoolId: "ap-southeast-1_8vBdk5PAd",
    userPoolWebClientId: "51e572vhkn38k7tuvsdst697b1",
    mandatorySignIn: true,
  },
});

export default {
  name: "ImportBaseCost",
  data: () => ({
    roles: [],
    loader: null,
    loading: false,
    loading2: false,
    loadBtn: false,
    selectedFile: [],
    arrayData: [],
    timeStamp: "",
    AuthStr: "",
    percent: "",
    selectedFileData: {
      fileName: "",
      fileSize: "",
    },
    informText: "",
    listVendors: [],
    vendor_code: "",
    total_lines: 0,
    informData: [],
    hedaers: [],
  }),

  methods: {
    onFileSelected(event) {
      this.headers = [];
      this.loader = "loading";
      this.selectedFile = [];
      this.selectedFile = event.target.files || event.dataTransfer.files;
      this.timeStamp =
        Date.now().toString() + Math.floor(Math.random() * 10).toString();
      if (!this.selectedFile.length) return;

      this.selectedFileData.fileName = this.selectedFile[0].name;
      this.selectedFileData.fileSize = this.selectedFile[0].size + " bytes";
      console.log(this.selectedFile[0]);
      this.createInput(this.selectedFile[0]);
    },

    createInput(file) {
      this.arrayData = [];
      this.informText = "";
      const l = this.loader;
      this[l] = !this[l];

      let promise = new Promise((resolve) => {
        var reader = new FileReader();
        var vm = this;
        reader.onload = (e) => {
          //console.log(e)
          e;
          resolve((vm.fileinput = reader.result));
        };
        reader.readAsText(file);
      });

      promise.then(
        (result) => {
          result;
          //console.log('fileinput: ',this.fileinput)
          var lines = this.fileinput.split("\n");

          var a = lines[0].split(",");
          var headers = [];
          for (const i of a) {
            headers.push(i.toString().trim());
          }

          //console.log("headers: ", headers);
          this.headers = [];

          this.headers.push({
            text: "LineNo",
            value: "LineNo",
            sortable: false,
          });

          for (var head of headers) {
            var item = {
              text: head,
              value: head,
              sortable: false,
            };
            this.headers.push(item);
          }

          for (var i = 1; i < lines.length; i++) {
            if (lines[i] != "") {
              //if (lines[i].indexOf(",,,,") < 0) {
              var obj = {};
              var currentline = this.$papa.parse(lines[i]).data[0];
              var check = true;

              for (var j = 0; j < headers.length; j++) {
                if (j == 0 && currentline[j].toString() == "") {
                  console.log("TRUE!!!!!!");
                  console.log("currentline: ", currentline);
                  check = false;
                }
                obj[headers[j]] = currentline[j].toString();
              }

              if (check) {
                this.arrayData.push(obj);
              }
              //}
            }
          }

          //console.log(this.arrayData.length);

          this.addProductNumber();
          this[l] = false;
          this.loader = null;
          console.log(this.arrayData);
        },
        (error) => {
          console.log(error);
        }
      );
      this.$refs.inputPreview.value = "";
    },

    addProductNumber() {
      this.total_lines = this.arrayData.length;
      for (let j = 0; j < this.arrayData.length; j++) {
        this.arrayData[j]["LineNo"] = j;
      }
    },

    async sendAPI(arr) {
      //console.log(arr);

      await this.$root.$refs.app.checkToken();

      var item = {
        orders: arr,
      };

      await axios
        .post(window.domain + "fulfillment/import-items", item)
        .then((response) => {
          if (response.data.error_message != "") {
            this.informText = response.data.error_message;
          } else if (response.data.data.invalid_orders.length > 0) {
            for (var a of response.data.data.invalid_orders) {
              this.informData.push(a);
            }
          }
        })
        .catch((error) => console.log("Err: ", error));
    },

    async uploadData() {
      const l = this.loader;
      this[l] = !this[l];
      this.loadBtn = true;

      var maxPerAPI = 500;
      var count = 0;
      var temp = [];
      for (var i = 0; i < this.arrayData.length; i++) {
        if (count > maxPerAPI) {
          //console.log(i);
          this.percent = Math.round((i / (this.arrayData.length - 1)) * 100);
          await this.sendAPI(temp);
          temp = [];
          count = 0;
        } else if (i == this.arrayData.length - 1) {
          //console.log(this.arrayData[i])
          this.percent = Math.round((i / (this.arrayData.length - 1)) * 100);
          //console.log("Finish: ", i);
          temp.push(this.arrayData[i]);
          await this.sendAPI(temp);
          temp = [];
          count = 0;
        } else {
          count += 1;
        }
        temp.push(this.arrayData[i]);
        //console.log(temp)
      }
      (this.selectedFile = null), (this.arrayData = []);

      this[l] = false;
      this.loader = null;
      this.loadBtn = false;

      if (this.informText == "" && this.informData.length >= 0) {
        if (this.informData.length > 0) {
          var text = "Invalid Lines: " + this.informData.join(", ");
          this.cancelData(text);
        } else {
          this.cancelData();
        }
      }
      //this.$root.$refs.sales.showAlert("importBaseCost", this.total_lines);
    },

    cancelData(text) {
      this.selectedFile = [];
      this.arrayData = [];
      this.$refs.inputPreview.value = "";
      const l = this.loader;
      this[l] = false;
      this.loader = null;
      if (text) this.$root.$refs.ordersList.turnOffDialog(text);
      else this.$root.$refs.ordersList.turnOffDialog();
    },

    check() {
      console.log("selectedFile", this.selectedFile);
      console.log("arrayData", this.arrayData);
      console.log("input", this.$refs.inputPreview.value);
    },
  },
  async mounted() {},
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
}

.tbl-data {
  overflow: scroll;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>