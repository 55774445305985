<template>
  <div>
    <v-col cols="12">
      <v-alert type="error" v-if="informText != ''">
        {{ informText }}
      </v-alert>

      <v-row>
        <v-col cols="6" class="border">
          <v-radio-group
            v-model="selected"
            :mandatory="false"
            class="small"
            row
            label="Export"
          >
            <v-radio
              :label="selectedItemText"
              :value="selectedItem.length"
              v-if="selectedItem.length > 0"
            ></v-radio>
            <v-radio
              :label="selectedItemText"
              :value="selectedItem.length"
              v-else
              disabled=""
            ></v-radio>
            <v-radio
              label="Orders matching your search"
              value="MatchingFilters"
            ></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="6">
          <v-row class="border">
            <v-col cols="2" style="text-align: left">Download: </v-col>
            <v-col cols="10" style="text-align: left">
              <template v-if="selected == selectedItem.length">
                <!-- {{listProductID.join(", ")}} -->
                Selected {{ this.selectedItem.length }} Item(s)
              </template>
              <template v-else> Orders matching your search </template>
              <!-- {{listProductID}} -->
            </v-col>

            <v-col cols="2" style="text-align: left">Current Filter</v-col>
            <v-col cols="10" style="text-align: left">
              <div v-if="filterData.status != ''">
                <b> Status: </b>{{ filterData.status }}
              </div>
              <div v-if="filterData.vendor_order_number != ''">
                <b> Order Number: </b>{{ filterData.vendor_order_number }}
              </div>
              <div v-if="filterData.item_id != ''">
                <b> Item Number: </b>{{ filterData.item_id }}
              </div>
              <div v-if="filterData.dates.length > 0">
                <b> Dates: </b>{{ filterData.dates.join(" ~ ") }}
              </div>
              <div v-if="filterData.vendor_code != ''">
                <b> Vendor: </b>{{ filterData.vendor_code }}
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-col cols="12" style="text-align: right">
        <v-btn
          color="success"
          @click="exportData"
          v-if="selected != '' && showBtn == true"
        >
          Export
        </v-btn>
        <v-btn
          color="success"
          class="btn btn-success"
          @click="exportData"
          v-else
          disabled
        >
          Export
        </v-btn>
        <!-- <button class="btn btn-success" @click="check">Check</button> -->
        &nbsp;

        <v-btn class="white--text" color="warning" @click="cancelData">
          Cancel <v-icon right dark>mdi-window-close</v-icon>
        </v-btn>
      </v-col>
    </v-col>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DialogDownload",

  props: {
    selectedItem: Array,
    filterData: Object,
  },

  data: () => ({
    informText: "",
    selected: "",
    selectedItemText: "",
    downloadLink: "",
    showBtn: true,
  }),

  methods: {
    async exportData() {
      this.showBtn = false;
      await this.$root.$refs.app.checkToken();

      var item = {
        export: this.selected,
        item_ids: [],
        filters: {},
      };

      if (this.selected == "MatchingFilters") {
        if (this.filterData.status != "")
          item.filters["status"] = this.filterData.status;

        if (this.filterData.vendor_order_number != "")
          item.filters["orderNumber"] = this.filterData.vendor_order_number;

        if (this.filterData.item_id != "")
          item.filters["itemID"] = this.filterData.item_id;

        if (this.filterData.vendor_code != "")
          item.filters["vendorCode"] = this.filterData.vendor_code;

        if (this.filterData.dates.length > 0) {
          if (this.filterData.dates[0] > this.filterData.dates[1]) {
            item.filters["createFrom"] = this.filterData.dates[1];
            item.filters["createTo"] = this.filterData.dates[0];
          } else {
            item.filters["createFrom"] = this.filterData.dates[0];
            item.filters["createTo"] = this.filterData.dates[1];
          }
        }
      } else {
        for (var a of this.selectedItem) {
          item.item_ids.push(a._id);
        }
        //item.item_ids = this.selectedItem;
      }

      await axios
        .post(window.domain + "fulfillment/export-items", item)
        .then((response) => {
          if (response.data.error_message != "") {
            this.informText = response.data.error_message;
            this.showBtn = false;
          } else {
            window.open(response.data.data.download_link, "_blank").focus();

            this.cancelData();
          }
        })
        .catch((error) => console.log("Error: ", error));
    },

    cancelData() {
      this.$root.$refs.ordersList.turnOffDialog();
    },
  },

  mounted() {
    this.selectedItemText = "Selected " + this.selectedItem.length + " Item(s)";
    console.log(this.selectedItemText);
  },
};
</script>

<style lang="scss" scoped>
</style>