import { render, staticRenderFns } from "./c-orders-list.vue?vue&type=template&id=3830c9f0&scoped=true&"
import script from "./c-orders-list.vue?vue&type=script&lang=js&"
export * from "./c-orders-list.vue?vue&type=script&lang=js&"
import style0 from "./c-orders-list.vue?vue&type=style&index=0&id=3830c9f0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3830c9f0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VBadge,VBtn,VCard,VCol,VDataTable,VDatePicker,VIcon,VList,VListItem,VListItemContent,VMenu,VNavigationDrawer,VRow,VSelect,VSpeedDial,VTab,VTextField})
