<template>
  <div>
    <v-col cols="12" v-if="alert == false">
      <v-alert type="error">
        {{ informText }}
      </v-alert>
    </v-col>

    <v-col cols="12" v-else-if="alert == true">
      <v-alert type="success">
        {{ informText }}
      </v-alert>
    </v-col>

    <v-col cols="12">
      <v-row>
        <template>
          <div class="text-h6 mb-1">
            Order Number: {{ item.vendor_order_number }} | Status:
            <b v-bind:style="{ color: sttColor }">{{ item.status }}</b>
          </div>
        </template>
        <v-spacer></v-spacer>
        <v-btn color="success" dark @click="saveData" v-if="roles.update_item"
          >SAVE</v-btn
        >
        &nbsp;
        <v-btn color="orange" dark @click="cancelData">CLOSE</v-btn>
      </v-row>
    </v-col>

    <v-col cols="12">
      <table width="100%">
        <tbody>
          <tr>
            <td colspan="4">
              <h3>TRACKING INFO</h3>
              <v-text-field
                label="Tracking Number Info"
                v-model="orderItem.tracking_number"
                hide-details
              ></v-text-field>
              <v-text-field
                label="Tracking Carrier"
                v-model="orderItem.tracking_carrier"
              ></v-text-field>
            </td>
            <td colspan="4">
              <h3>ITEM INFO</h3>
              <div>
                <b>Item Name:</b> {{ item.quantity }} x {{ item.item_name }}
              </div>
              <div><b>Item ID:</b> {{ item.item_id }}</div>
              <div>
                <b>Design Link: </b>
                <a
                  v-if="item.design_link != '' && item.design_link"
                  :href="item.design_link"
                  target="_blank"
                  style="text-decoration: none"
                  >Link</a
                >
              </div>
              <div>
                <b>Mockup Link: </b>
                <a
                  v-if="item.mockup_link != '' && item.mockup_link"
                  :href="item.mockup_link"
                  target="_blank"
                  style="text-decoration: none"
                  >Link</a
                >
              </div>
            </td>
            <td colspan="4">
              <h3>PROCESSING TIME</h3>
              <div><b>Created At:</b> {{ item.created_at }}</div>
              <div><b>Paid At:</b> {{ item.paid_at }}</div>
              <div><b>Process At:</b> {{ item.processed_at }}</div>
              <div><b>Shipped At:</b> {{ item.shipped_at }}</div>
              <div><b>Delivered At:</b> {{ item.delivered_at }}</div>
            </td>
          </tr>
        </tbody>
      </table>

      <table
        v-if="
          $store.state.gbaUser.indexOf('staff') < 0 &&
          $store.state.gbaUser.indexOf('partner') < 0
        "
      >
        <tbody>
          <tr>
            <td colspan="6">
              <h3>SHIPPING INFO</h3>
              <br />

              <template v-if="item.shipping_info">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="6">
                      <div>
                        <v-text-field
                          label="First Name"
                          v-model="item.shipping_info.first_name"
                          dense
                        ></v-text-field>
                      </div>

                      <div>
                        <v-text-field
                          label="Phone"
                          dense
                          v-model="item.shipping_info.phone"
                        ></v-text-field>
                      </div>
                      <div>
                        <v-text-field
                          label="Address 1"
                          dense
                          v-model="item.shipping_info.address1"
                        ></v-text-field>
                      </div>

                      <div>
                        <v-text-field
                          label="City"
                          dense
                          v-model="item.shipping_info.city"
                        ></v-text-field>
                      </div>

                      <div>
                        <v-text-field
                          label="Country"
                          dense
                          v-model="item.shipping_info.country"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div>
                        <v-text-field
                          label="Last Name"
                          dense
                          v-model="item.shipping_info.last_name"
                        ></v-text-field>
                      </div>

                      <div>
                        <v-text-field
                          label="Company"
                          dense
                          v-model="item.shipping_info.company"
                        ></v-text-field>
                      </div>

                      <div>
                        <v-text-field
                          label="Address 2"
                          dense
                          v-model="item.shipping_info.address2"
                        ></v-text-field>
                      </div>
                      <div>
                        <v-text-field
                          label="Province"
                          dense
                          v-model="item.shipping_info.province"
                        ></v-text-field>
                      </div>
                      <div>
                        <v-text-field
                          label="ZIP"
                          dense
                          v-model="item.shipping_info.zip"
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </template>
              <template v-else>N/A</template>
            </td>
            <td colspan="6">
              <h3>
                COMPLAINS
                <v-btn color="info" small @click="addComplain">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </h3>

              <v-col
                cols="12"
                v-for="(i, index) of item.complains"
                :key="index"
              >
                <div style="margin-bottom: 10px">
                  <v-row>
                    <v-col cols="10">
                      <v-row>
                        <v-col cols="5">
                          <v-select
                            densen
                            hide-details
                            :items="complainList"
                            label="Complain Type"
                            style="font-size: 14px"
                            v-model="i.complain_type"
                          ></v-select>
                        </v-col>
                        <v-col cols="7">
                          <v-textarea
                            hide-details
                            rows="1"
                            label="Complain Message"
                            v-model="i.complain_message"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        style="float: right"
                        small
                        color="red"
                        dark
                        @click="delComplain(i)"
                        ><v-icon small>mdi-delete</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </td>
          </tr>
        </tbody>
      </table>
      <table v-else>
        <tbody>
          <tr>
            <td colspan="12">
              <h3>SHIPPING INFO</h3>
              <br />

              <template v-if="item.shipping_info">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="6">
                      <div>
                        <v-text-field
                          label="First Name"
                          v-model="item.shipping_info.first_name"
                          dense
                        ></v-text-field>
                      </div>

                      <div>
                        <v-text-field
                          label="Phone"
                          dense
                          v-model="item.shipping_info.phone"
                        ></v-text-field>
                      </div>
                      <div>
                        <v-text-field
                          label="Address 1"
                          dense
                          v-model="item.shipping_info.address1"
                        ></v-text-field>
                      </div>

                      <div>
                        <v-text-field
                          label="City"
                          dense
                          v-model="item.shipping_info.city"
                        ></v-text-field>
                      </div>

                      <div>
                        <v-text-field
                          label="Country"
                          dense
                          v-model="item.shipping_info.country"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div>
                        <v-text-field
                          label="Last Name"
                          dense
                          v-model="item.shipping_info.last_name"
                        ></v-text-field>
                      </div>

                      <div>
                        <v-text-field
                          label="Company"
                          dense
                          v-model="item.shipping_info.company"
                        ></v-text-field>
                      </div>

                      <div>
                        <v-text-field
                          label="Address 2"
                          dense
                          v-model="item.shipping_info.address2"
                        ></v-text-field>
                      </div>
                      <div>
                        <v-text-field
                          label="Province"
                          dense
                          v-model="item.shipping_info.province"
                        ></v-text-field>
                      </div>
                      <div>
                        <v-text-field
                          label="ZIP"
                          dense
                          v-model="item.shipping_info.zip"
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </template>
              <template v-else>N/A</template>
            </td>
          </tr>
        </tbody>
      </table>
    </v-col>

    <!-- {{ item }} -->
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment-timezone";

export default {
  name: "OrderDetailComponent",

  props: {
    orderItem: Object,
    listStatus: Array,
  },

  data: () => ({
    informText: "",
    //e1: 1,
    sttColor: "",
    item: [],
    alert: null,
    complainList: [
      "damage, faults, and incorrect",
      "lost or delayed delivery",
      "poor quality",
    ],
  }),

  computed: {
    roles() {
      return this.$store.state.gbaUserPermissions.chinese_suppliers;
    },
  },

  methods: {
    loadStep() {
      //this.e1 = this.listStatus.indexOf(this.orderItem.status) + 1;

      switch (this.orderItem.status) {
        case "New":
          this.sttColor = "orange";
          break;
        case "Unpaid":
          this.sttColor = "orange";
          break;
        case "Paid":
          this.sttColor = "#3796F3";
          break;
        case "In Production":
          this.sttColor = "#3796F3";
          break;
        case "Shipped":
          this.sttColor = "#3796F3";
          break;
        case "Delivered":
          this.sttColor = "#4CAF50";
          break;
        case "Canceled":
          this.sttColor = "red";
          break;
        default:
        // code block
      }

      console.log("sttColor: ", this.sttColor);
    },

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    addComplain() {
      var item = {
        complain_type: "",
        complain_message: "",
      };
      if (this.item.complains) {
        this.item.complains.push(item);
      } else {
        this.item["complains"] = [];
        this.item.complains.push(item);
      }
      console.log("item: ", this.item);
      this.$forceUpdate();
    },

    delComplain(item) {
      this.item.complains = this.item.complains.filter((t) => t != item);
      this.$forceUpdate();
    },

    async loadData() {
      await this.$root.$refs.app.checkToken();
      var timeZone = localStorage.getItem("timeZone");

      await axios
        .get(window.domain + "fulfillment/" + this.orderItem._id)
        .then((response) => {
          if (response.data.error_message == "") {
            this.item = response.data.data.item;

            if (this.item.created_at)
              this.item.created_at = moment(this.item.created_at)
                //.tz(this.$store.state.timeZoneSelected)
                .tz(timeZone)
                .format("YYYY-MM-DD HH:MM:SS");

            if (this.item.delivered_at)
              this.item.delivered_at = moment(this.item.delivered_at)
                //.tz(this.$store.state.timeZoneSelected)
                .tz(timeZone)
                .format("YYYY-MM-DD HH:MM:SS");

            if (this.item.paid_at)
              this.item.paid_at = moment(this.item.paid_at)
                //.tz(this.$store.state.timeZoneSelected)
                .tz(timeZone)
                .format("YYYY-MM-DD HH:MM:SS");

            if (this.item.processed_at)
              this.item.processed_at = moment(this.item.processed_at)
                //.tz(this.$store.state.timeZoneSelected)
                .tz(timeZone)
                .format("YYYY-MM-DD HH:MM:SS");

            if (this.item.shipped_at)
              this.item.shipped_at = moment(this.item.shipped_at)
                //.tz(this.$store.state.timeZoneSelected)
                .tz(timeZone)
                .format("YYYY-MM-DD HH:MM:SS");
          } else {
            this.informText = response.data.data.error_message;
          }
        })
        .catch((error) => console.log("Error: ", error));
    },

    async saveData() {
      await this.$root.$refs.app.checkToken();

      // console.log("axios");
      var complains = [];

      if (this.item.complains) {
        complains = this.item.complains;
      }

      var response = null;

      if (this.item.shipping_info) {
        response = await axios
          .put(window.domain + "fulfillment/" + this.orderItem._id, {
            tracking_number: this.orderItem.tracking_number,
            tracking_carrier: this.orderItem.tracking_carrier,
            complains: complains,
            shipping_first_name: this.item.shipping_info.first_name,
            shipping_last_name: this.item.shipping_info.last_name,
            shipping_phone: this.item.shipping_info.phone,
            shipping_address1: this.item.shipping_info.address1,
            shipping_address2: this.item.shipping_info.address2,
            shipping_city: this.item.shipping_info.city,
            shipping_province: this.item.shipping_info.province,
            shipping_country: this.item.shipping_info.country,
            shipping_zip: this.item.shipping_info.zip,
          })
          .then((response) => {
            return response;
          });
      } else {
        response = await axios
          .put(window.domain + "fulfillment/" + this.orderItem._id, {
            tracking_number: this.orderItem.tracking_number,
            tracking_carrier: this.orderItem.tracking_carrier,
            complains: complains,
          })
          .then((response) => {
            return response;
          });
      }

      if (response.data.error_message != "") {
        this.informText = response.data.error_message;
        this.alert = false;
      } else {
        this.informText = "Order Info Saved";
        this.alert = true;
      }

      await this.sleep(5000);
      this.alert = null;
      this.informText = null;
    },

    cancelData() {
      this.$root.$refs.ordersList.turnOffDialog();
    },

    completeData() {
      this.$root.$refs.ordersList.turnOffDialognUpdate();
    },
  },

  async mounted() {
    await this.loadData();
    this.loadStep();
  },
};
</script>

<style lang="scss" scoped>
.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none;
}

table,
th,
td {
  border: 1px solid black;
  padding: 10px;
}

table {
  border-collapse: collapse;
  border: 1px solid black;
}

td {
  width: 8.33%;
  vertical-align: top;
  text-align: left;
}
</style>